













































































































































































































































































































































































































































import Vue from 'vue'
//import { ApiResponse, Contact, Sale, RequisitionItem, PurchaseOrder, RelatedSaleItem } from '@/interfaces'
import { ApiResponse, Contact, RequisitionItem, PurchaseOrder, RelatedSaleItem } from '@/interfaces'
import { stockStatuses } from '@/formHelpers'
import ContactForm from '@/components/ContactForm.vue'
import RegroupModal from '@/components/RegroupModal.vue'

interface Key {
  type: string
  code: string
}

export default Vue.extend({
  components: { ContactForm, RegroupModal },
  data() {
    return {
      busy: false,
      displayEmpty: false,
      //sale: {} as Sale,
      saleId: null as string | null,
      search: null as string | null,
      itemsPool: [] as RequisitionItem[],
      items: [] as RequisitionItem[],
      activeItem: null as null | RequisitionItem,
      activeIndex: -1,
      acquereurs: [] as Record<string, string | null>[],
      formModelStockStatus: null as string | null,
      formModelEndPrice: null as string | null,
      formModelPlateNumber: null as string | null,
      formModelAcquereur: null as string | null,
      formModelDescription: null as string | null,
      acquereurInfos: {},
      showAddContactModal: false,
      newContact: null as Contact | null,
      newContactType: '',
      newContactId: null as string | null,
      resetContactForm: false,
      infoPrefill: null as string | null,
      stockStatuses,
      polling: null as null | number,
      pollingBusy: false,
      showInterencheresModal: false,
      showDrouotModal: false,
      ieRadio: false,
      progress: 0,
      totalMoney: '0,00 €',
      removedTotalMoney: '0,00 €',
      estimationTotalMoney: '0,00 € - 0,00€',
      purchaseOrdersList: [] as PurchaseOrder[],
      keypressList: [] as Key[],
      isFormDisabled: true,
      activeItemId: null as number | null,
      showRegroupModal: false,
      relatedSaleItems: [] as RelatedSaleItem[],
      allowChangeActive: false,
    }
  },
  watch: {
    newContact(newVal) {
      if (newVal !== null) {
        switch (this.newContactType) {
          case 'acquereur':
            this.updateAcquereur(newVal)
            break
        }
        this.newContact = null
        this.newContactType = ''
        this.resetContactForm = !this.resetContactForm
      }
    },
    activeItem(newVal) {
      if (newVal !== null) {
        // Setting form data
        this.formModelStockStatus = newVal.stockStatus ?? null
        this.formModelEndPrice = newVal.endPrice ?? null
        this.formModelPlateNumber = newVal.plateNumber ?? null
        this.formModelDescription = newVal.description ?? null
        this.activeItemId = newVal.id ?? null
        this.fetchPurchaseOrders()
      }

      this.resetAcquereurInfos()
      this.formModelAcquereur = newVal.acquereur?.id?.toString() ?? null
      if (newVal.acquereur?.id) {
        this.acquereurs = [
          {
            label: newVal.acquereur?.formName ?? '-',
            value: newVal.acquereur?.id.toString(),
          },
        ]

        this.acquereurInfos = this.setAcquereurInfos(newVal.acquereur)
      }
      this.isFormDisabled = false
    },
    items(newVal) {
      const relatedSaleItems = [] as RelatedSaleItem[]
      newVal.forEach((item: RequisitionItem) => {
        let itemNumber = item.itemNumber
        if (item.itemSubNumber) {
          itemNumber = `${itemNumber}-${item.itemSubNumber}`
        }
        const relatedSaleItem = {
          id: item.id,
          itemNumber,
          requisitionId: item?.requisition?.id,
          description: item.description,
        } as RelatedSaleItem
        relatedSaleItems.push(relatedSaleItem)
      })
      this.relatedSaleItems = relatedSaleItems
    },
  },
  mounted() {
    this.fetchSale()
    this.fetchItems()
    this.startPolling()

    // Listening for arrow down
    document.addEventListener('keydown', this.keyPressDown)
    document.addEventListener('keyup', this.keyPressUp)
  },
  updated() {
    const stockStatusInput = document.getElementById('activeItemStockStatus')
    if (stockStatusInput) {
      stockStatusInput.setAttribute('tabindex', '-1')
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyPressDown)
    document.removeEventListener('keyup', this.keyPressUp)
    this.stopPolling()
  },
  methods: {
    formatPurchaseOrderData(purchaseOrder: PurchaseOrder) {
      let purchaseOrderInfo = ''
      if (purchaseOrder.plateNumber) {
        purchaseOrderInfo = `${purchaseOrderInfo}${purchaseOrder.plateNumber} /`
      }
      if (purchaseOrder.acquereur?.formName) {
        purchaseOrderInfo = `${purchaseOrderInfo} ${purchaseOrder.acquereur?.formName} /`
      }
      if (purchaseOrder.amount) {
        purchaseOrderInfo = `${purchaseOrderInfo} ${this.displayCurrency(purchaseOrder.amount?.toString() ?? '')}`
      }
      if (purchaseOrder.mustCall && purchaseOrder.acquereurPhoneNumber) {
        purchaseOrderInfo = `${purchaseOrderInfo} / ${purchaseOrder.acquereurPhoneNumber}`
      }
      return purchaseOrderInfo
    },
    keyPressDown(e: KeyboardEvent) {
      if (this.keypressList.findIndex((key: Key) => key.type === e.key) === -1) {
        this.keypressList.push({ type: e.key, code: e.code })
      }
      if (this.keypressList.findIndex((key: Key) => key.type === 'Shift') > -1) {
        if (e.code === 'ArrowDown') {
          this.changeItemActive('next')
        }

        if (e.code === 'ArrowUp') {
          this.changeItemActive('prev')
        }
      }
    },
    keyPressUp(e: KeyboardEvent) {
      let index = -1
      if ((index = this.keypressList.findIndex((key: Key) => key.type === e.key)) > -1) {
        this.keypressList.splice(index, 1)
      }
    },
    fetchSale() {
      // on a uniquement besoin de l'ID de la vente apparemment
      this.saleId = this.$route.params.saleId
      // this.busy = true
      // this.sale = {}
      // this.$api
      //   .get(`/sale/${this.$route.params.saleId}`)
      //   .then((response) => {
      //     const apiResponse = response.data as ApiResponse

      //     this.sale = apiResponse.data
      //   })
      //   .catch(() => {
      //     this.$notify({
      //       type: 'error',
      //       title: 'Erreur',
      //       message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
      //     })
      //   })
      //   .finally(() => {
      //     this.busy = false
      //   })
    },
    fetchPurchaseOrders() {
      this.busy = true

      this.$api
        .get(`/purchase-order/list/${this.$route.params.saleId}/${this.activeItem?.id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.purchaseOrdersList = apiResponse.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
        })
    },
    fetchItems() {
      this.busy = true
      this.displayEmpty = false
      this.itemsPool = []
      this.$api
        .get(`/sale/${this.$route.params.saleId}/dashboard`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          if (apiResponse.data.length === 0) {
            this.displayEmpty = true
          } else {
            this.refreshItems(apiResponse.data)
          }
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
          this.setProgress()
          this.searchSellingItem()
        })
    },
    refreshItems(items: RequisitionItem[]) {
      this.itemsPool = []
      this.items = []

      items.forEach((item) => {
        this.itemsPool.push(this.formattedItemData(item))
        this.items.push(this.formattedItemData(item))
      })
    },
    resetActiveItem() {
      this.activeItem = null

      document.querySelectorAll('.item').forEach((el) => {
        el.classList.remove('active')
      })
    },
    searchItems() {
      if (this.search !== null && this.search !== '') {
        this.items = this.itemsPool.filter((o) => {
          return (
            o.plateNumber === this.search ||
            o.acquereur?.lastName?.toUpperCase().includes(this.search?.toUpperCase() ?? '')
          )
        })
      } else {
        this.refreshItems(this.itemsPool)
      }
    },
    filterItems() {
      this.resetActiveItem()
      this.searchItems()
    },
    setTotal() {
      let total = 0

      this.itemsPool.forEach((item: RequisitionItem) => {
        if (
          item.stockStatus == 'sold' ||
          item.stockStatus == 'amicably_sold' ||
          item.stockStatus == 'unfulfilled' ||
          item.stockStatus == 'to_buyer_back'
        )
          total = total + parseInt(item.endPrice ?? '0')
      })

      this.totalMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(total)
    },
    setRemovedTotal() {
      let total = 0

      this.itemsPool.forEach((item: RequisitionItem) => {
        if (item.stockStatus == 'removed') {
          total = total + parseInt(item.endPrice ?? '0')
        }
      })

      this.removedTotalMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(total)
    },
    setEstimationTotal() {
      let estimationLow = 0
      let estimationHigh = 0

      this.itemsPool.forEach((item: RequisitionItem) => {
        estimationLow = estimationLow + parseInt(item.estimationLow ?? '0')
        estimationHigh = estimationHigh + parseInt(item.estimationHigh ?? '0')
      })

      const estimationLowMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationLow)
      const estimationHighMoney = new Intl.NumberFormat('fr-Fr', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(estimationHigh)

      this.estimationTotalMoney = `${estimationLowMoney} - ${estimationHighMoney}`
    },
    setProgress(): void {
      let progress = 0
      let totalItems = this.itemsPool.length
      let soldItems = 0

      if (totalItems > 0) {
        this.itemsPool.forEach((item: RequisitionItem) => {
          if (item.stockStatus !== 'entry' && item.stockStatus !== 'selling' && item.stockStatus !== 'expertise') {
            soldItems++
          }
        })
        progress = Math.round((soldItems / totalItems) * 100)
      }

      this.progress = progress

      this.setTotal()
      this.setRemovedTotal()
      this.setEstimationTotal()
    },
    displayProgress(): number {
      let soldItems = 0

      if (this.itemsPool.length > 0) {
        this.itemsPool.forEach((item: RequisitionItem) => {
          if (item.stockStatus !== 'entry' && item.stockStatus !== 'selling' && item.stockStatus !== 'expertise') {
            soldItems++
          }
        })
      }

      return soldItems
    },
    displayCurrency(str: string | null) {
      if (str !== null) {
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'EUR',
        }).format(parseFloat(str ?? '0'))
      } else {
        return ''
      }
    },
    formatEstimations(item: RequisitionItem): string {
      const estimationLow = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(parseFloat(item.estimationLow ?? '0'))

      const estimationHigh = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(parseFloat(item.estimationHigh ?? '0'))

      return `${estimationLow} - ${estimationHigh}`
    },
    formatStatus(status: string | null): string {
      return this.stockStatuses.find((stockStatus) => stockStatus.value === status)?.label ?? '-'
    },
    checkStatus(status: string, endPrice: number, acquereur: string) {
      if (status == 'entry' || status == 'expertise') return 'warning'
      if (
        (status == 'sold' || status == 'amicably_sold' || status == 'unfulfilled' || status == 'to_buyer_back') &&
        !acquereur
      )
        return 'inprogress'

      if (
        (status == 'sold' || status == 'amicably_sold' || status == 'unfulfilled' || status == 'to_buyer_back') &&
        !endPrice
      )
        return 'inprogress'

      if (
        acquereur &&
        status != 'sold' &&
        status != 'amicably_sold' &&
        status != 'unfulfilled' &&
        status != 'to_buyer_back'
      )
        return 'inprogress'

      switch (status) {
        case 'sold':
        case 'removed':
        case 'not_shown':
        case 'destroyed':
        case 'left':
        case 'regrouped':
        case 'pre-emption':
        case 'unfulfilled':
        case 'given_back':
        case 'to_buyer_back':
          return 'completed'

        default:
          return false
      }
    },
    makeActive(item: RequisitionItem) {
      document.querySelectorAll('.item').forEach((el) => {
        el.classList.remove('active')
      })

      const clickedRow = document.getElementById(`item${item.id}`)

      if (clickedRow) {
        clickedRow.classList.add('active')
      }

      this.activeItem = {
        ...item,
        formattedReservePrice: this.displayCurrency(item.reservePrice ?? null),
        formattedItemNumber: this.getItemNumber(item),
        coverURL: this.getImage(item),
      }
      this.updateActiveItemIndex()

      // Reposition scroll
      // repsitionnent de la liste au click pas souhaité
      // const el = document.getElementById(`item${this.activeItem.id}`)
      // window.scrollTo({
      //   top: el?.offsetTop,
      //   left: 0,
      //   behavior: 'smooth',
      // })

      // Make end price focused
      this.$nextTick(() => {
        document.getElementById('endPrice')?.focus()
      })

      // Prevent automatic change when API returns data
      this.allowChangeActive = false
    },
    moveToItem(item: RequisitionItem) {
      // Reposition scroll
      const el = document.getElementById(`item${item.id}`)
      window.scrollTo({
        top: el?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    },
    changeItemActive(direction: string) {
      if (this.activeItem == null && this.items.length > 0) {
        this.makeActive(this.items[0])
      } else {
        let i = 0
        this.items.every((item: RequisitionItem) => {
          if (this.activeItem?.id === item.id) {
            return false
          } else {
            i++
            return true
          }
        })

        let index = 0

        switch (direction) {
          case 'next':
            index = i + 1 === this.items.length ? this.items.length - 1 : i + 1
            break

          case 'prev':
            index = i - 1 < 0 ? this.items.length - 1 : i - 1
            break
        }

        this.makeActive(this.items[index])
      }
    },
    updateActiveItemIndex() {
      this.activeIndex = this.itemsPool.findIndex((item: RequisitionItem) => item.id === this.activeItem?.id)
    },
    submit() {
      if (this.activeItem !== null) {
        const endPrice = this.formModelEndPrice ? parseFloat(this.formModelEndPrice) : 0
        const plateNumber = this.formModelPlateNumber ?? ''

        if (this.formModelStockStatus == 'selling') {
          if (endPrice > 0 && ((plateNumber != '' && plateNumber != '0') || this.formModelAcquereur !== null)) {
            this.formModelStockStatus = 'sold'
          } else if (plateNumber == '' || plateNumber == '0') {
            this.formModelStockStatus = 'removed'
          }
        }

        this.allowChangeActive = true

        this.$api
          .put(`/sale/${this.$route.params.saleId}/item/${this.activeItem.id}`, {
            stockStatus: this.formModelStockStatus ?? null,
            endPrice: this.formModelEndPrice ? parseFloat(this.formModelEndPrice) : null,
            plateNumber:
              this.formModelPlateNumber == null || this.formModelPlateNumber == '' ? null : this.formModelPlateNumber,
            acquereur: this.formModelAcquereur === '' ? 'NULL' : this.formModelAcquereur,
            description: this.formModelDescription,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            const itemToUpdateIndex = this.itemsPool.findIndex(
              (item: RequisitionItem) => item.id === apiResponse.data.updatedItem.id
            )
            this.updateItem(apiResponse.data.updatedItem, itemToUpdateIndex)
            this.updateListItems(apiResponse.data.updatedItems)
            this.searchItems()
          })
          .catch((error) => {
            if (!error.response?.data?.notification) {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            if (this.allowChangeActive === true) {
              this.changeItemActive('next')
            }
            this.setProgress()
          })
      }
    },
    updateItem(item: RequisitionItem, index: number | null = null) {
      const returnedItem = this.formattedItemData(item)
      const indexToUpdate = index ?? this.activeIndex
      this.itemsPool.splice(indexToUpdate, 1, returnedItem)
      if (!this.polling) {
        this.activeItem = {
          ...this.activeItem,
          description: returnedItem.description,
        }
      }
    },
    goToRequisitionItem() {
      this.$router.push({
        name: 'requisition_item_edit',
        params: {
          itemId: this.activeItem?.id?.toString() ?? '',
        },
      })
    },
    formattedItemData(item: RequisitionItem) {
      let description = '-'

      if (item?.description) {
        if (item?.description?.length > 50) {
          description = item.description.substring(0, 50) + '...'
        } else {
          description = item.description
        }
      }

      return {
        ...item,
        formattedItemNumber: this.getItemNumber(item),
        formattedEstimations: this.formatEstimations(item),
        formattedStatus: this.formatStatus(item.stockStatus ?? null),
        formattedStartingPrice: this.displayCurrency(item.startingPrice ?? null),
        formattedEndPrice: this.displayCurrency(item.endPrice ?? null),
        formattedReservePrice: this.displayCurrency(item.reservePrice ?? null),
        formattedDescription: description,
        formattedVatRate: item.vatRate,
        coverURL: this.getImage(item),
      }
    },
    getImage(item: RequisitionItem) {
      // poll return (requisitionItem)
      if (item.requisitionItemFiles && item.requisitionItemFiles.length > 0) {
        return item.requisitionItemFiles[0].urlThumb
      }
      // list return (requisitionItemList opimized)
      if (item.thumbnail) {
        return item.thumbnail.url
      }
      return null
    },
    updateListItems(items: RequisitionItem[]) {
      items.forEach((updatedItem: RequisitionItem) => {
        const itemToUpdateIndex = this.itemsPool.findIndex((item: RequisitionItem) => item.id === updatedItem.id)
        this.updateItem(updatedItem, itemToUpdateIndex)
      })
    },
    searchAcquereurs(search?: string) {
      this.acquereurs = this.searchContacts(search)
    },
    searchContacts(
      search?: string,
      category = null as string | null,
      minLength = 2
    ): Record<string, string | null>[] {
      if (search == '*' || (search && search.length >= minLength)) {
        if (search == '*') {
          search = ''
        }

        this.infoPrefill = search

        this.busy = true
        let contacts = [] as Record<string, string | null>[]
        this.$api
          .get('/contacts', {
            params: {
              search: search ?? null,
              category,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse
            if (apiResponse.data && Array.isArray(apiResponse.data)) {
              apiResponse.data.forEach((contact: Contact) => {
                let contactLabel = contact.formName
                if (contact.city) {
                  contactLabel = `${contactLabel} - ${contact.city}`
                }
                contacts.push({
                  label: contactLabel ?? null,
                  value: contact.id?.toString() ?? null,
                })
              })
            }
          })
          .finally(() => {
            this.busy = false
          })

        return contacts
      } else {
        return []
      }
    },
    clearNewContact() {
      this.acquereurs = []
    },
    resetAcquereurInfos() {
      this.acquereurInfos = {
        siret: '-',
        vat: '-',
        fullName: '-',
        formName: '-',
        address: '-',
        email: '-',
        phone: '-',
        mobile: '-',
        companyName: '-',
      }
    },
    setAcquereurInfos(contact: Contact | null) {
      let address = '-'
      if (contact?.address != null) {
        address = `${contact?.address ?? ''} ${contact?.address2 ?? ''} ${contact?.postalCode ?? ''} ${
          contact?.city ?? ''
        }`
      }

      return {
        siret: contact?.siret ?? '-',
        vat: contact?.vat ?? '-',
        fullName: contact?.fullName ?? '-',
        formName: contact?.formName ?? '-',
        address: this.nl2br(address),
        email: contact?.email ?? '-',
        phone: contact?.phone ?? '-',
        mobile: contact?.mobile ?? '-',
        companyName: contact?.companyName ?? '-',
      }
    },
    loadAcquereur(id: string) {
      if (id === 'add') {
        this.openContactForm('acquereur')
      } else {
        this.loadAcquereurInfo(id)
      }
    },
    loadAcquereurInfo(id: string) {
      if (id == '') {
        this.acquereurInfos = this.setAcquereurInfos(null)
        return
      }
      this.busy = true
      this.$api
        .get(`/contact/${id}`)
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          this.acquereurInfos = this.setAcquereurInfos(apiResponse.data)
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
        .finally(() => {
          this.busy = false
          this.submit()
        })
    },
    nl2br(str: string): string {
      if (typeof str !== 'undefined') {
        return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      } else {
        return ''
      }
    },
    openContactForm(type: string, contact?: Contact) {
      if (!this.busy) {
        this.showAddContactModal = true
        this.newContactType = type

        // This is kind of a hack, but otherwise the contact form component does not detect the change and therefor does not trigger an API call.
        // On the contact form side, the value '0' is always ignored as it cannot correspond to a valid MySQL id.
        this.newContactId = '0'
        this.$nextTick(() => {
          if (contact?.id == null) {
            this.newContactId = null
          } else {
            this.newContactId = contact?.id?.toString() ?? null
          }
        })
      }
    },
    closeContactForm() {
      this.showAddContactModal = false
      this.newContact = null
      this.formModelAcquereur = null
      this.resetAcquereurInfos()
      this.clearNewContact()
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.newContact = contact
    },
    updateContactsList(contactsList: Record<string, string | null>[], contact: Contact) {
      contactsList.length = 0
      contactsList.push({
        label: contact.formName ?? '-',
        value: contact.id?.toString() ?? null,
      })
    },
    updateAcquereur(contact: Contact) {
      this.updateContactsList(this.acquereurs, contact)
      this.formModelAcquereur = null
      this.acquereurInfos = this.setAcquereurInfos(contact)
      this.$nextTick(() => {
        this.formModelAcquereur = contact.id?.toString() ?? null
        this.submit()
      })
    },
    sendDrouotCSV() {
      this.showDrouotModal = false
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir importer les acquéreurs de cette vente depuis Drouot ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        const filePicker = document.getElementById('drouotFilePicker') as HTMLInputElement

        if (filePicker) {
          filePicker.click()
        }
      })
    },
    sendInterencheresCSV() {
      this.showInterencheresModal = false
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir importer les acquéreurs de cette vente depuis Interencheres ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        const filePicker = document.getElementById('interencheresFilePicker') as HTMLInputElement

        if (filePicker) {
          filePicker.click()
        }
      })
    },
    updateAcquereursInfos(type: string) {
      this.activeItem = null

      let filePicker = null
      let url = null

      switch (type) {
        case 'drouot':
          filePicker = document.getElementById('drouotFilePicker') as HTMLInputElement
          url = `/sale/${this.$route.params.saleId}/importdrouot`
          break

        case 'interencheres':
          filePicker = document.getElementById('interencheresFilePicker') as HTMLInputElement
          url = `/sale/${this.$route.params.saleId}/importinterencheres`
          break
      }

      if (filePicker && url) {
        const formData = new FormData()

        formData.append('file', (filePicker.files as FileList)[0])
        formData.append('updateprices', this.ieRadio.toString())

        this.$api
          .post(url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            // Refreshing items
            this.itemsPool = apiResponse.data
            this.updateListItems(apiResponse.data)

            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })
          })
          .catch((error) => {
            if (error.response?.status == 500) {
              //   this.$notify({
              //     type: 'error',
              //     title: 'Erreur',
              //     message:
              //       "Il semble que le fichier que vous essayez d'envoyer n'est pas au bon format. Merci de vérifier et réessayer plus tard.",
              //   })
              // } else {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
      }
    },
    getItemNumber(item: RequisitionItem): string {
      const num = item.itemNumber ?? '-'
      const sub = item.itemSubNumber ? `-${item.itemSubNumber}` : ''

      return `${num}${sub}`
    },
    startPolling() {
      const delay = process.env.VUE_APP_SALE_POLLING_DELAY ? parseInt(process.env.VUE_APP_SALE_POLLING_DELAY) : 10

      this.polling = window.setInterval(() => {
        this.pollSale()
      }, delay * 1000)
    },
    stopPolling() {
      if (this.polling !== null) {
        clearInterval(this.polling)
      }

      this.polling = null
    },
    pollSale() {
      if (!this.pollingBusy) {
        this.pollingBusy = true
        const delay = process.env.VUE_APP_SALE_POLLING_DELAY ? parseInt(process.env.VUE_APP_SALE_POLLING_DELAY) : 10

        this.$api(`/sale/${this.saleId}/poll`, { params: { delay } })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.data.length > 0) {
              apiResponse.data.forEach((updatedItem: RequisitionItem) => {
                let itemIndex = -1
                const formattedUpdatedItem = this.formattedItemData(updatedItem)
                // Only ItemsPool, which always contains all the items, is updated...
                if ((itemIndex = this.itemsPool.findIndex((item) => updatedItem.id == item?.id)) > -1) {
                  this.itemsPool.splice(itemIndex, 1, formattedUpdatedItem)
                } else {
                  this.itemsPool.push(formattedUpdatedItem)
                }

                if (this.activeItem !== null && updatedItem.id == this.activeItem.id) {
                  this.activeItem = formattedUpdatedItem
                }
              })
              this.sortItems()
              //... SearchItems filters the items if this.search is not null
              this.searchItems()
              this.updateActiveItemIndex()
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 500) {
                this.stopPolling()
              }
            }
          })
          .finally(() => {
            this.pollingBusy = false
          })
      }
    },
    sortItems() {
      this.itemsPool.sort(this.sortByItemNumber)
    },
    sortByItemNumber(itemA: RequisitionItem, itemB: RequisitionItem) {
      const itemAFullItemNumber = itemA.itemNumber ? parseFloat(this.getItemNumber(itemA).replace('-', '.')) : 0
      const itemBFullItemNumber = itemB.itemNumber ? parseFloat(this.getItemNumber(itemB).replace('-', '.')) : 0
      if (itemAFullItemNumber === itemBFullItemNumber) {
        return 0
      }

      return itemAFullItemNumber < itemBFullItemNumber ? -1 : 1
    },
    setPurchaseOrderData(index: number) {
      const activePurchaseOrder = this.purchaseOrdersList[index] ?? null
      if (activePurchaseOrder) {
        // Create new Contact object with purchaseOrderAcquereur infos
        const purchaseOrderAcquereur = {
          ...activePurchaseOrder.acquereur,
        } as Contact
        // Update contacts list to select the purchaseOrderAcquereur
        this.updateContactsList(this.acquereurs, purchaseOrderAcquereur)
        // Set activeItem infos with purchaseOrder infos
        this.formModelAcquereur = purchaseOrderAcquereur.id?.toString() ?? null
        this.acquereurInfos = this.setAcquereurInfos(activePurchaseOrder.acquereur ?? null)
        this.formModelPlateNumber = activePurchaseOrder.plateNumber ?? null
        this.$nextTick(() => {
          document.getElementById('endPrice')?.focus()
        })
      }
    },
    openRegroupModal() {
      if (this.formModelStockStatus === 'regrouped') {
        this.showRegroupModal = true
      }
    },
    triggerShowRegroupModal(value: boolean) {
      this.showRegroupModal = value
    },
    newItem() {
      if (this.activeItem !== null) {
        const data = {
          itemId: this.activeItem?.id?.toString(),
        }

        this.busy = true

        this.$api
          .post(`/requisition/${this.activeItem.requisition?.id?.toString()}/newitem`, data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            const formattedUpdatedItem = this.formattedItemData(apiResponse.data)

            this.items.push(formattedUpdatedItem)
            this.itemsPool.push(formattedUpdatedItem)

            this.$nextTick(() => {
              this.makeActive(this.items[this.items.length - 1])
            })
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    searchSellingItem() {
      const defaultActiveItem = this.items.find((item: RequisitionItem) => item.stockStatus === 'selling') ?? null
      if (defaultActiveItem) {
        this.makeActive(defaultActiveItem)
        this.moveToItem(defaultActiveItem)
      }
    },
  },
})
